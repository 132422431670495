import * as bootstrap from "bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

/* AOS init */
//AOS.init();

/* show content body */
document.querySelector("main").className = "loaded";

setTimeout(() => {
  document.querySelector("#site-loading").className = "hide";
  AOS.init();
}, 1500);
